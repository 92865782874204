<template>
  <div v-if="settlements && comparatives">
    <validation-observer
      #default="{ handleSubmit }"
      ref="propertyInfoRules"
    >
      <b-form
        v-if="evaluationData"
        class="mt-1"
        @submit.prevent="handleSubmit(addEvaluation)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-alert
              v-model="showMessage"
              dismissible
              :variant="messageType"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span
                  class="ml-25"
                  v-html="message"
                />
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-alert
              v-model="showMessage"
              dismissible
              :variant="messageType"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span
                  class="ml-25"
                  v-html="message"
                />
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="12"
          >
            <div class="d-flex">
              <feather-icon
                icon="InfoIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                معلومات عامة
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="mb-2">
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            md="12"
            lg="6"
          >
            <div class="column-1">
              <h6 class="section-label">
                تاريخ الوقوف على العقار
              </h6>
              <b-card-text class="font-small-2">
                {{ evaluationData.evaluated_at ? moment(String(evaluationData.evaluated_at)).format('YYYY-MM-DD') : '' }}
              </b-card-text>
            </div>
          </b-col>
          <b-col
            cols="6"
            md="12"
            lg="6"
          >
            <div class="column-2">
              <h6 class="section-label">
                تاريخ الإعتماد
              </h6>
              <b-card-text class="font-small-2">
                {{ evaluationData.accredited_at ? moment(String(evaluationData.accredited_at)).format('YYYY-MM-DD') : ''
                }}
              </b-card-text>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="12"
            lg="12"
          >
            <div class="column-1">
              <h6 class="section-label">
                ملاحظات المقيّم
              </h6>
              <b-card-text class="font-small-2">
                {{ evaluationData.comments }}
              </b-card-text>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="12"
            lg="12"
          >
            <div class="column-1">
              <h6 class="section-label">
                وصف العقار
              </h6>
              <b-card-text class="font-small-2">
                {{ evaluationData.property_description }}
              </b-card-text>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="mt-2"
            cols="12"
            md="12"
            lg="12"
          >
            <div class="d-flex">
              <feather-icon
                icon="InfoIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                تقييم العقار
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="mb-2">
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="(evaluationArea, i) in evaluationData.areas"
            :id="evaluationArea.id"
            :key="i"
            ref="row"
            cols="12"
            md="12"
            lg="12"
          >
            <b-row>
              <b-col>
                <h6 class="section-label">
                  نوع المساحة
                </h6>
                <b-card-text class="font-small-2">
                  {{ evaluationArea.area.name }}
                </b-card-text>
              </b-col>
              <b-col>
                <h6 class="section-label">
                  مسمى المساحة
                </h6>
                <b-card-text class="font-small-2">
                  {{ evaluationArea.label }}
                </b-card-text>
              </b-col>
              <b-col
                :key="`surface-${i}`"
                cols="12"
                md="2"
                lg="2"
              >
                <!-- Field: area.surface -->
                <h6 class="section-label">
                  المساحة
                </h6>
                <b-card-text class="font-small-2">
                  {{ evaluationArea.surface }}
                </b-card-text>
              </b-col>
              <b-col
                :key="`value-${i}`"
                cols="12"
                md="2"
                lg="2"
              >
                <h6 class="section-label">
                  سعر المتر
                </h6>
                <b-card-text class="font-small-2">
                  {{ evaluationArea.value }}
                </b-card-text>
              </b-col>
              <b-col
                cols="12"
                md="2"
                lg="2"
                style="margin: auto"
              >
                <span style="font-weight: bold">
                  {{
                    (
                      (evaluationArea.surface || 0) *
                      (evaluationArea.value || 0)
                    ).toFixed(2)
                  }}
                  ريال
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <hr class="mb-2">
          </b-col>
          <b-col
            cols="12"
            md="2"
            lg="2"
            style="margin: auto"
            class="text-success"
          >
            <span> الكلفة التقديرية </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            lg="8"
            style="margin: auto"
            class="text-success"
          >
            <span>
              {{ totalToWords() }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="2"
            lg="2"
            style="margin: auto"
            class="text-success"
          >
            <span> {{ calculateTotal() }} ريال </span>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <hr class="mb-2">
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="12"
          >
            <div class="d-flex">
              <feather-icon
                icon="GlobeIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                العقارات المقارنة
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="mb-2">
          </b-col>
          <b-col
            cols="12"
            md="12"
            lg="12"
          >
            <b-row
              v-for="(comparative, k) in evaluationData.comparatives"
              :id="`comparatif-${k}`"
              :key="k"
            >
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <div class="mb-1">
                  <h5 class="ml-50">
                    {{ comparativesLabels[k] }}
                  </h5>
                  <span class="text-success"><strong>إجمالي السعر: </strong>
                    {{
                      (evaluationData.comparatives[k].area || 0) *
                        (evaluationData.comparatives[k].price_per_meter || 0)
                    }}
                    ريال سعودي</span>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="2"
                lg="2"
              >
                <!-- Field: feature.description -->
                <h6 class="section-label">
                  وصف العقار
                </h6>
                <b-card-text class="font-small-2">
                  {{ comparative.description }}
                </b-card-text>
              </b-col>
              <b-col
                v-for="(field, c) in comparatives[0].features"
                :id="field.id"
                :key="c"
                cols="12"
                :md="6 / comparatives[0].features.length"
                :lg="6 / comparatives[0].features.length"
              >
                <!-- Field: field.feature.name -->
                <h6 class="section-label">
                  {{ field.feature.display_name }}
                </h6>
                <b-card-text class="font-small-2">
                  {{ evaluationData.comparatives[k]['features'][c].value }}
                </b-card-text>
              </b-col>
              <b-col
                cols="12"
                md="2"
                lg="2"
              >
                <!-- Field: feature.area -->
                <h6 class="section-label">
                  المساحة
                </h6>
                <b-card-text class="font-small-2">
                  {{ comparative.area }}
                </b-card-text>
              </b-col>
              <b-col
                cols="12"
                md="2"
                lg="2"
              >
                <!-- Field: feature.price_per_meter-->
                <h6 class="section-label">
                  سعر المتر
                </h6>
                <b-card-text class="font-small-2">
                  {{ comparative.price_per_meter }}
                </b-card-text>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row key="settlement-table">
          <b-col
            cols="12"
            md="12"
            lg="12"
          >
            <div class="d-flex">
              <feather-icon
                icon="GlobeIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                جدول التسويات
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="mb-2">
          </b-col>
          <b-col>
            <b-table-simple
              hover
              striped
              bordered
              responsive
            >
              <b-thead head-variant="light">
                <b-tr class="text-center">
                  <b-th colspan="1">
&nbsp;
                  </b-th>
                  <b-th colspan="2">
                    المقارن الأول
                  </b-th>
                  <b-th colspan="2">
                    المقارن الثاني
                  </b-th>
                  <b-th colspan="2">
                    المقارن الثالث
                  </b-th>
                </b-tr>
                <b-tr
                  v-for="(
                    field, c
                  ) in evaluationData.comparatives[0].settlments.slice(
                    0,
                    settlements[0].settlemts1.length
                  )"
                  :id="field.id"
                  :key="`settlment-1-date-${c}`"
                  class="text-center"
                >
                  <b-td
                    v-if="settlements[0].settlemts1[c].feature.value_type == 'date'
                    "
                    class="text-bold text-left"
                  >
                    {{ settlements[0].settlemts1[c].feature.display_name }}
                  </b-td>
                  <template v-for="(comparative, k) in evaluationData.comparatives">
                    <b-td
                      v-if="settlements[0].settlemts1[c].feature.value_type == 'date'
                      "
                      :key="`date-${k}-${c}`"
                      colspan="2"
                    >
                      <h3 class="section-label">
                        {{ evaluationData.comparatives[k].settlments[c].value }}
                      </h3>
                    </b-td>
                  </template>
                </b-tr>
                <b-tr class="text-center">
                  <b-th />
                  <b-th>القيمة</b-th>
                  <b-th>نسبةالتسوية</b-th>
                  <b-th>القيمة</b-th>
                  <b-th>نسبةالتسوية</b-th>
                  <b-th>القيمة</b-th>
                  <b-th>نسبةالتسوية</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(field, c) in settlements[0].settlemts1"
                  :id="field.id"
                  :key="`settlment1-1-${c}`"
                  class="text-center"
                >
                  <b-th
                    v-if="['string', 'object'].includes(field.feature.value_type)"
                    class="text-bold text-left"
                  >
                    {{ field.feature.display_name }}
                  </b-th>
                  <template v-for="k in [0, 1, 2]">
                    <b-td
                      v-if="field.feature.value_type == 'string'"
                      :key="`settlemts1-input-${k}-${c}`"
                      colspan="2"
                    >
                      <h3 class="section-label">
                        {{ evaluationData.comparatives[k].settlments[c].value }}
                      </h3>
                    </b-td>
                    <template v-if="field.feature.value_type == 'object'">
                      <b-td
                        v-for="(subField, s) in field.feature.value_options
                          .fields"
                        :key="`subfield-${s}-${k}`"
                      >
                        <h3 class="section-label">
                          {{ evaluationData.comparatives[k].settlments[c].value[subField.name] }}
                        </h3>
                      </b-td>
                    </template>
                  </template>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    اجمالي نسب التسويات
                  </b-th>
                  <b-td colspan="2">
                    {{ calculatePrimaryTotalAdjustment(0) }}%
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryTotalAdjustment(1)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryTotalAdjustment(2)
                    }}
                  </b-td>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    قيمة نسب التسويات
                  </b-th>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryAdjustmentValue(0)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryAdjustmentValue(1)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryAdjustmentValue(2)
                    }}
                  </b-td>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    السعر بعد التعديلات
                  </b-th>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryPriceAfterAdjustments(0)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryPriceAfterAdjustments(1)
                    }}
                  </b-td>
                  <b-td colspan="2">
                    {{
                      calculatePrimaryPriceAfterAdjustments(2)
                    }}
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="(
                    field, c
                  ) in evaluationData.comparatives[0].settlments.slice(
                    settlements[0].settlemts1.length
                  )"
                  :id="settlements[0].settlemts2[
                    getSettlmentIndex(field.property_feature_id)
                  ].id
                  "
                  :key="`settlment2-1-${c}`"
                  style="position: relative"
                  class="text-center"
                >
                  <b-th
                    v-if="c > 0"
                    class="text-bold text-left"
                  >
                    <!-- //////////// -->
                    {{ evaluationData.comparatives[0].settlments[
                      c + settlements[0].settlemts1.length
                    ].label }}
                    <!-- //////////// -->
                  </b-th>
                  <b-th
                    v-if="c == 0"
                    class="text-bold text-left"
                  >
                    {{
                      settlements[0].settlemts2[
                        getSettlmentIndex(field.property_feature_id)
                      ].feature.display_name
                    }}
                  </b-th>
                  <template v-for="k in [0, 1, 2]">
                    <b-td
                      v-if="settlements[0].settlemts2[
                        getSettlmentIndex(field.property_feature_id)
                      ].value_type == 'string'
                      "
                      :key="`settlemts2-input-${k}-${c}`"
                      colspan="2"
                    >
                      <h3 class="section-label">
                        {{ evaluationData.comparatives[k].settlments[
                          c + settlements[0].settlemts1.length
                        ].value }}
                      </h3>
                    </b-td>
                    <template
                      v-if="settlements[0].settlemts2[
                        getSettlmentIndex(field.property_feature_id)
                      ].feature.value_type == 'object'
                      "
                    >
                      <b-td
                        v-for="(subField, s) in settlements[0].settlemts2[
                          getSettlmentIndex(field.property_feature_id)
                        ].feature.value_options.fields"
                        :key="`subfield-${s}-${k}`"
                      >
                        <h3
                          v-if="subField.value_type == 'readonly'"
                          class="section-label"
                        >
                          {{ getRelatedValue(subField, k) }}
                        </h3>
                        <h3
                          v-else
                          class="section-label"
                        >
                          {{ evaluationData.comparatives[k].settlments[
                            c + settlements[0].settlemts1.length
                          ].value[subField.name] }}
                        </h3>
                      </b-td>
                    </template>
                  </template>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    اجمالي نسب التسويات
                  </b-th>
                  <b-td colspan="2">
                    {{ calculateTotalAdjustment(0) }}%
                  </b-td>
                  <b-td colspan="2">
                    {{ calculateTotalAdjustment(1) }}
                  </b-td>
                  <b-td colspan="2">
                    {{ calculateTotalAdjustment(2) }}
                  </b-td>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    قيمة نسب التسويات
                  </b-th>
                  <b-td colspan="2">
                    {{ calculateAdjustmentValue(0) }}
                  </b-td>
                  <b-td colspan="2">
                    {{ calculateAdjustmentValue(1) }}
                  </b-td>
                  <b-td colspan="2">
                    {{ calculateAdjustmentValue(2) }}
                  </b-td>
                </b-tr>
                <b-tr class="text-center">
                  <b-th class="text-bold text-left">
                    السعر بعد التعديلات
                  </b-th>
                  <b-td colspan="2">
                    {{ calculatePriceAfterAdjustments(0) }}
                  </b-td>
                  <b-td colspan="2">
                    {{ calculatePriceAfterAdjustments(1) }}
                  </b-td>
                  <b-td colspan="2">
                    {{ calculatePriceAfterAdjustments(2) }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row
          v-for="(group, index) in features"
          :id="group.id"
          :key="index"
        >
          <b-col
            cols="12"
            md="12"
            lg="12"
          >
            <div class="d-flex">
              <feather-icon
                icon="GlobeIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                {{ group.display_name }}
              </h4>
            </div>
          </b-col>
          <b-col cols="12">
            <hr class="mb-2">
          </b-col>
          <b-col
            v-for="(feature, j) in group.features"
            :id="feature.id"
            :key="j"
            cols="12"
            md="6"
            lg="6"
          >
            <!-- Field: feature.name -->
            <h3 class="section-label text-dark">
              {{ getFeatureLabel(group, j, feature.feature.display_name) }}
            </h3>
            <h3
              v-if="feature.feature.value_type === 'boolean' && evaluationData.features[calculateFeatureIndex(feature)].value == 'true'"
              class="section-label text-success"
            >
              نعم
            </h3>
            <h3
              v-else-if="feature.feature.value_type === 'boolean' && evaluationData.features[calculateFeatureIndex(feature)].value == 'false'"
              class="section-label text-danger"
            >
              لا
            </h3>
            <h3
              v-else-if="feature.feature.value_type === 'boolean' && evaluationData.features[calculateFeatureIndex(feature)].value != 'true' && evaluationData.features[calculateFeatureIndex(feature)].value != 'false'"
              class="section-label text-warning"
            >
              غير محدد
            </h3>
            <h3
              v-else
              class="section-label text-danger"
            >
              {{ evaluationData.features[calculateFeatureIndex(feature)].value }}
            </h3>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script >
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BThead,
  BTr,
  BTh,
  BTableSimple,
  BTbody,
  BTd,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { nArabicWords } from 'narabicwords';
import { evaluationMixin } from '@/@core/mixins/modules/evaluation';
import { propertyEvaluationMixin } from '@/@core/mixins/modules/evaluation/propertyEvaluation';
import moment from 'moment';
import evaluationStore from '../evaluationStore';

export default {
  components: {
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BThead,
    BTr,
    BTh,
    BTableSimple,
    BTbody,
    BTd,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, evaluationMixin, propertyEvaluationMixin],
  props: {
    evaluation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      config: {
        locale: Arabic,
      },
      fields: ['المقارن الاول', 'المقارن الثاني', 'المقارن الثالث'],
      settlement: {},
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      comparativesLabels: ['المقارن الأول', 'المقارن الثاني', 'المقارن الثالث'],
      yesNoOptions: [
        { text: 'نعم', value: true },
        { text: 'لا', value: false },
      ],
      moment,
    };
  },
  mounted() {
    this.$root.$on('validateEvaluation', async () => {
      this.$root.$emit('evaluationValidated', {
        success: true,
        data: this.evaluationData,
      });
    });
  },
  beforeDestroy() {
    this.$root.$off('validateProperty');
  },
  methods: {
    // Get feature label
    getFeatureLabel(group, index, label) {
      // if group.options.is_ordered_list exists and its true we prefix label with current number starting from 1
      if (group.options && group.options.is_ordered_list) {
        return `${index + 1} - ${label}`;
      }
      return label;
    },
    // Get related value
    getRelatedValue(settlment, parentIndex) {
      // get related field name from settlment value_options.from
      const relatedFieldName = settlment.value_options.from;
      // check if field is nested object
      if (relatedFieldName.includes('.')) {
        // get the nested object name
        const nestedObjectName = relatedFieldName.split('.')[0];
        // if nested object is plural then its an object
        if (nestedObjectName.endsWith('s')) {
          // get the nested object value
          const nestedObjectValue = this.evaluationData[nestedObjectName][parentIndex];
          // get the nested object field name
          const nestedObjectFieldName = relatedFieldName.split('.')[1];
          // get the nested object field value
          const nestedObjectFieldValue = nestedObjectValue[nestedObjectFieldName];
          // return the nested object field value
          return nestedObjectFieldValue;
        }
        // get the nested object value
        const nestedObjectValue = this.evaluationData[nestedObjectName][parentIndex];
        // get the nested object field name
        const nestedObjectFieldName = relatedFieldName.split('.')[1];
        // get the nested object field value
        const nestedObjectFieldValue = nestedObjectValue[nestedObjectFieldName];
        // return the nested object field value
        return nestedObjectFieldValue;
      }
      // get the related field value
      const relatedFieldValue = this.evaluationData.comparatives[parentIndex][relatedFieldName];
      // return the related field value
      return relatedFieldValue;
    },
    // Get evaluation feature index by feature name
    getFeatureIndex(featureId) {
      return this.evaluationData.features.findIndex(
        (feature) => feature.feature_id === featureId,
      );
    },
    totalToWords() {
      const total = this.calculateTotal();
      // We split the total to get the integer and decimal parts
      const [integer, decimal] = total.split('.');
      let totalWords = `${nArabicWords(integer)}`;
      if (decimal) totalWords += ` فاصل ${nArabicWords(decimal)}`;
      return `${totalWords} ريال سعودي`;
    },
    calculateTotal() {
      let total = 0;
      this.evaluationData.areas.forEach((area) => {
        total += (area.surface || 0) * (area.value || 0);
      });
      return total.toFixed(2);
    },
    featureChanged(feature) {
      // console.log(feature);
    },
  },
  setup(props) {
    const { evaluation } = props;
    const { property } = evaluation;
    const { type } = property;
    const EVALUATION_STORE_MODULE_NAME = 'evaluation';
    const evaluationData = ref(evaluation);
    // Register evaluation
    if (!store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.registerModule(EVALUATION_STORE_MODULE_NAME, evaluationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.unregisterModule(EVALUATION_STORE_MODULE_NAME);
    });

    const features = ref([]);
    const comparatives = ref(null);
    const settlements = ref(null);
    const areas = ref([]);
    const allAreas = ref([]);
    store.dispatch('evaluation/fetchAllAreas').then((response) => {
      const { data: allAreasData } = response.data;
      allAreas.value = allAreasData.map((area) => ({
        ...area,
        value: area.id,
        label: area.name,
      }));
    });
    // Retrieve related features
    if (type) {
      store
        .dispatch('evaluation/fetchFeatures', { id: type.id })
        .then((response) => {
          const { data } = response.data;
          features.value = data.groups
            .filter((group) => ['property_notes'].includes(group.name))
            .map((group) => {
              group.features = group.features
                .filter((feature) => feature.property_type_id == type.id)
                .map((feature) => {
                  if (feature.value_type == 'enum') {
                    feature.value_options.options = feature.value_options.options.map((option) => ({
                      value: option,
                      label: option,
                    }));
                  }
                  return feature;
                });
              return group;
            });
          comparatives.value = data.groups
            .filter((group) => ['property_comparative'].includes(group.name))
            .map((group) => {
              group.features = group.features
                .map((feature) => {
                  if (feature.value_type == 'enum') {
                    feature.value_options.options = feature.value_options.options.map((option) => ({
                      value: option,
                      label: option,
                    }));
                  }
                  return feature;
                });
              return group;
            });

          settlements.value = data.groups
            .filter((group) => ['property_settlements'].includes(group.name))
            .map((group) => {
              group.features = group.features
                .filter((feature) => feature.property_type_id == type.id)
                .map((feature) => {
                  if (feature.value_type == 'enum') {
                    feature.value_options.options = feature.value_options.options.map((option) => ({
                      value: option,
                      label: option,
                    }));
                  }
                  if (feature.value_type == 'object') {
                    feature.fields.map((field) => {
                      if (field.value_type == 'enum') {
                        field.value_options.options = field.value_options.options.map((option) => ({
                          value: option,
                          label: option,
                        }));
                      }
                      return field;
                    });
                  }
                  return feature;
                });
              group.settlemts1 = group.features.filter((feature) => [
                'transaction_date',
                'market_conditions',
                'financing_conditions',
                'sale_conditions',
              ].includes(feature.feature.name));
              group.settlemts2 = group.features.filter(
                (feature) => ![
                  'transaction_date',
                  'market_conditions',
                  'financing_conditions',
                  'sale_conditions',
                ].includes(feature.feature.name),
              );
              return group;
            });

          store
            .dispatch('evaluation/fetchAreas', { id: type.id })
            .then((response) => {
              const { data: areasData } = response.data;
              areas.value = areasData.map((area) => ({
                value: area.id,
                label: area.name,
              }));
            })
            .catch((error) => {
              if (error.response && error.response.status === 404) {
                evaluationData.value = undefined;
              } else {
                console.log(error);
              }
            });
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            evaluationData.value = undefined;
          } else {
            console.log(error);
          }
        });
    }
    evaluation.comparatives.map((comparative) => {
      comparative.settlments.map((settlement) => {
        if (!settlement.value) {
          settlement.value = '';
        }
      });
    });
    const blankEvaluationData = evaluation;

    const resetevaluationData = () => {
      evaluationData.value = JSON.parse(JSON.stringify(blankEvaluationData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetevaluationData);

    return {
      areas,
      allAreas,
      features,
      comparatives,
      settlements,
      evaluationData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
